/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// INITIAL STATE

    // Set initial state.
    const setInitialState = () => {
        return {
            overlay: {
                components: []
            },
            API: {
                host: process.env.NODE_ENV === "development" ? "http://localhost/" : "https://www.findmeacarejob.co.uk/",
                path: process.env.NODE_ENV === "development" ? "CCJobs" : "API"
            },
            Filter: {
                // these initial values have been imported from the JobFilter.vue
                orderBy: 'Best Match',
                location: '',
                distance: '50',
                upperHours: '72',
                wageRate: '0',
                salaryRate: '0',
                joiningBonus: false,
                paidBreaks: false,
                hasCar: -1,
                type: ['Permanent', 'Temporary', 'Maternity Cover', 'Part-Time', 'Full-Time', 'Contract', 'Zero Hours'],
                industry: ['Older People', 'Supported Living', 'DomCare'],
                availability: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                title: '',
                keywords: '',
            }
        };
    };

    // Define state.
    const state = setInitialState();

//


// GETTERS

    // Determine whether the overlay has any active components.
    const isOverlayActive = function (state) {
        return state.overlay.components.length > 0 ? true : false;
    };

    // Return API state.
    const API = function (state) {
        return state.API;
    };

    // Return the saved Filtering options.
    const Filter = function (state) {
        return state.Filter;
    }

    // Define getters.
    const getters = {
        isOverlayActive,
        API,
        Filter
    };

//


// MUTATIONS

    // Update overlay component.
    const updateOverlayComponents = function (state, payload) {

        // Destructure payload.
        const { id, action } = payload;

        // Switch on action value.
        switch (action) {

            // Add component to array.
            case 'add':
                state.overlay.components.push(id);
                break;

            // Remove component from array.
            case 'remove':
                state.overlay.components = state.overlay.components.filter(
                    item => item !== id
                );
                break;

            // Display error message.
            default:
                console.log('Invalid action!');

        }

    };

    // Update filter
    const updateFilterData = function (state, payload) {
        const { filterTable } = payload

        state.Filter = filterTable
    }

    // Define mutations.
    const mutations = {
        updateOverlayComponents,
        updateFilterData
    };

//


// ACTIONS

    // Add overlay component.
    const addOverlayComponent = function (context, id) {
        
        // Invoke the update overlay components mutator, passing action and target id.
        context.commit(
            'updateOverlayComponents',
            {
                id: id,
                action: 'add'
            }
        );

    };

    // Remove overlay component.
    const removeOverlayComponent = function (context, id) {
        
        // Invoke the update overlay components mutator, passing action and target id.
        context.commit(
            'updateOverlayComponents',
            {
                id: id,
                action: 'remove'
            }
        );

    };

    // Updates the filter table
    const updateFilterTable = function (context, tableData) {
        context.commit(
            'updateFilterData',
            {
                filterTable: tableData
            }
        )
    }

    // Define actions.
    const actions = {
        addOverlayComponent,
        removeOverlayComponent,
        updateFilterTable
    };

//


// EXPORT

    export default {
        state,
        getters,
        mutations,
        actions
    };

//
