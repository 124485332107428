/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// DEPENDENCIES

    // Utilities.
    import { updateTitle, updateDescription, updateKeywords } from "@/common/Utilities";

//


// FUNCTION DEFINITION

    // Update meta information.
    export const updateMetaInformation = (to) => {

        // If title meta attribute exists.
        if (Object.prototype.hasOwnProperty.call(to.meta, 'title')) {

            // Define alias for title.
            const title = to.meta.title;

            // Invoke the 'updateTitle' utilitiy.
            updateTitle(title);

        }

        // If title meta attribute exists.
        if (Object.prototype.hasOwnProperty.call(to.meta, 'description')) {

            // Define alias for description.
            const description = to.meta.description;

            // Invoke the 'updateDescription' utilitiy.
            updateDescription(description);

        }

        // If keywords meta attribute exists.
        if (Object.prototype.hasOwnProperty.call(to.meta, 'keywords')) {

            // Define alias for keywords.
            const keywords = to.meta.keywords;

            // Invoke the 'updateKeywords' utilitiy.
            updateKeywords(keywords);

        }

    };

//


// EXPORT

    export default updateMetaInformation;

//
