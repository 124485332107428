/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// DEPENDENCIES

    // Router.
    import { createRouter, createWebHistory } from 'vue-router';

    // Routes.
    import { routes } from './routes';

    // Navigation guards.
    import { updateMetaInformation } from '@/router/updateMetaInformation';

//


// ROUTER

    // Define router.
    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes,
        scrollBehavior() {
            return { top: 0 }
        }
    });

    // Update meta information.
    router.beforeEach(updateMetaInformation);

    // Export.
    export default router

//
