/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// DEPENDENCIES

    import { createApp } from 'vue'
    import App from './App.vue'
    import router from './router'
    import store from './store'
    import VueGtag from "vue-gtag";

//


// CREATE APPLICATION

    createApp(App)
        .use(store)
        .use(router)
        .use(
            VueGtag,
            {
                pageTrackerExcludedRoutes: [
                    'resource-not-found'
                ],
                config: { id: "G-PKZXGPCTDR" }
            }, 
            router
        )
        .mount('#app');

//
