/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// CATCH ALL

    // Define catch all.
    export const catchAll = {
        path: '/:catchAll(.*)',
        redirect: { name: 'resource-not-found' }
    };

//


// ROUTES

    // Define routes.
    export const routes = [
        {
            path: '/',
            name: 'landing',
            meta: {
                title: 'Landing',
                description: 'Placeholder Description',
                keywords: ['Keyword 1', 'Keyword 2', 'Keyword 3']
            },
            component: () => import('@/views/landing/Index')
        },
        {
            path: '/job-search',
            name: 'job-search',
            meta: {
                title: 'Job Search',
                description: 'Placeholder Description',
                keywords: ['Keyword 1', 'Keyword 2', 'Keyword 3'],
                sitemap: {
                    ignoreRoute: true
                }
            },
            component: () => import('@/views/jobSearch/Index')
        },
        {
            path: '/job-details',
            name: 'job-details',
            meta: {
                title: 'Job Details',
                description: 'Placeholder Description',
                keywords: ['Keyword 1', 'Keyword 2', 'Keyword 3'],
                sitemap: {
                    ignoreRoute: true
                }
            },
            component: () => import('@/views/jobDetails/Index')
        },
        {
            path: '/resource-not-found',
            name: 'resource-not-found',
            meta: {
                title: 'Resource Not Found',
                sitemap: {
                    ignoreRoute: true
                }
            },
            component: () => import('@/views/resourceNotFound/Index')
        },
        catchAll
    ];

//


// EXPORT

    export default routes;

//
