/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// DEPENDENCIES

    // Vuex.
    import { createStore } from 'vuex';

    // Modules.
    import application from './application';

//


// CREATE STORE

    const store = createStore({
        modules: {
            application
        }
    });

//


// EXPORT

    export default store;

//