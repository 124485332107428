<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <header>
        <div
            class="wrapper"
            @click="goToLanding"
            title="Go to Landing"
        >
            <Image
                filePath="logo-mono.svg"
                alternativeText="FindMeACareJob logo"
                class="logo"
                
            />
            <div class="site-title">
                FindMeACareJob
            </div>
        </div>
    </header>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Image from '@/components/generic/Image';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Image
        };

    //

    // METHODS

        // Go to landing.
        const goToLanding = function () {
            this.$router.push({
                name: 'landing'
            });
        };

        // Define methods.
        const methods = {
            goToLanding
        };

    //

    // EXPORT

        export default {
            name: 'Header',
            components,
            methods
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        header {
            position: fixed;
            top: 0;
            width: 100%;
            height: $header-height;
            padding: 1rem;
            background: {
                color: $colour-primary;
            }
            z-index: 10;
            box-shadow: 0 0 .5rem $colour-shadow;
        }

    //

    // CLASSES

        .wrapper {
            display: flex;
            gap: .5rem;
            align-content: center;
            align-items: center;
            justify-content: center;
            height: 100%;
            cursor: pointer;
            @media screen and (min-width: $large) {
                justify-content: flex-start;
                gap: .75rem;
            }
            &:hover {
                filter: drop-shadow(0 0 5px $colour-light);
            }
        }

        .logo {
            height: 100%;
        }

    //

</style>
