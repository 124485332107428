/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// DEPENDENCIES

    // UUID.
    import { v4 as uuidv4 } from 'uuid';

//


// METHODS

    // Generate ID.
    export const generateID = function () {

        // Generate ID using UUID.
        const id = uuidv4();

        // Return generated ID.
        return id;

    };

    // Generate key.
    export const generateKey = function() {

        // Obtain current date.
        const currentDate = new Date();

        // Deconstruct date object.
        const seconds = currentDate.getSeconds();
        const minutes = currentDate.getMinutes();
        const hours = currentDate.getHours();
        const day = currentDate.getDate();
        const year = currentDate.getFullYear();

        // Define string components.
        const a = seconds * day * year;
        const b = minutes * day * year;
        const c = hours * day * year;

        // Construct string.
        const securityKey = `${a}_${b}_${c}`;

        // Return string.
        return securityKey;

    };

    // Determine whether object has valid property values.
    export const hasValidProperties = function (object) {

        // Define base 'hasValue' property.
        let hasValue = false;
        
        // For each property of the passed object.
        for (const property in object) {

            // If the properties value is truthy.
            if (object[property].value !== undefined && object[property].value !== null) {
                
                // Set 'hasValue' to true.
                hasValue = true;

                // End loop.
                break;

            }

        }

        // Return 'hasValue' state.
        return hasValue;

    };

    // Convert week string array.
    export const resolveWeekArray = function (array) {

        // Define days.
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        // For each element of the days array.
        days.forEach(function (element, index) {
            
            // If the provided array contains the value return '1' else return '0';
            days[index] = array.includes(element) ? '1' : '0'

        });

        // Return joined array string.
        return days.join(',');

    };

    // Update title.
    export const updateTitle = function (title) {

        // Update window page title.
        document.title = `FindMeACareJob | ${title}`;

    };

    // Update description.
    export const updateDescription = function (description) {

        // Update document description.
        document.getElementById('description').setAttribute('content', description); 

    };

    // Update keywords.
    export const updateKeywords = function (keywords) {

        // Update document keywords.
        document.getElementById('keywords').setAttribute('content', keywords); 

    }

//


// EXPORT

    export default {
        generateID,
        hasValidProperties,
        resolveWeekArray,
        updateTitle,
        updateDescription,
        updateKeywords
    };

//
