<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <footer>
        <Image
            filePath="logo-mono.svg"
            alternativeText="FindMeACareJob logo"
            class="logo"
        />
        <div class="site-title">FindMeACareJob</div>
        <div class="author">Powered by <a href="https://www.carecontrolsystems.co.uk/" target="_blank">Care Control Systems</a></div>
    </footer>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Image from '@/components/generic/Image';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Image
        };

    //

    // EXPORT

        export default {
            name: 'Footer',
            components
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        footer {
            display: grid;
            gap: .75rem;
            align-content: center;
            justify-items: center;
            padding: 1.5rem;
            background: {
                color: $colour-primary;
            }
            box-shadow: 0 0 .1rem $colour-shadow;;
        }

    //

    // CLASSES

        .logo {
            height: calc(#{$header-height} - 2rem);
        }

        .author {
            color: $colour-light;
            font-weight: 400;
            text-align: center;
            a {
                text-decoration: none;
                color: $colour-light;
                font-weight: 600;
                &:hover {
                    filter: drop-shadow(0 0 5px $colour-light);
                }
            }
        }

    //

</style>
