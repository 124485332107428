<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <div>
        <Header />
            <router-view v-slot="{ Component }">
                <transition name="fade">
                    <component :is="Component" />
                </transition>
            </router-view>
        <Footer />
        <Overlay />
    </div>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Header from '@/components/application/Header';
        import Footer from '@/components/application/Footer';
        import Overlay from '@/components/application/overlay/Overlay';

        // Stylesheets.
        import '@/styles/theme.scss';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Header,
            Footer,
            Overlay
        };

    //

    // EXPORT

        export default {
            name: 'App',
            components
        };

    //

</script>
