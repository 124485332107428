<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <Transition name="fade" appear>
        <div
            v-if="isActive"
            class="overlay"
        >
            <slot />
        </div>
    </Transition>
</template>


<script>

    // COMPUTED

        // Determine overlay is active from store state.
        const isActive = function () {
            return this.$store.getters.isOverlayActive;
        };

        // Define computed.
        const computed = {
            isActive
        };

    //

    // EXPORT

        export default {
            name: 'Overlay',
            computed
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .overlay {
            width: 100vw;
            height: 100vh;
            display: grid;
            position: fixed;
            align-items: center;
            justify-items: center;
            top: 0;
            left: 0;
            z-index: 11;
            background: {
                color: rgba(0, 0, 0, .3);
            }
            backdrop-filter: blur(5px);
        }

    //

</style>
