<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <img
        :src="imageSource"
        :alt="alternativeText"
        :title="titleText"
        :class="colour ? colour : ''"
    />
</template>


<script>

    // PROPS

        // Define props.
        const props = {
            filePath: {
                type: String,
                required: true
            },
            alternativeText: {
                type: String,
                required: true
            },
            titleText: {
                type: String
            },
            colour: {
                type: String
            }
        };

    //

    // COMPUTED

        // Resolve image source.
        const imageSource = function () {

            // Import image using constructed file path.
            return require(`@/assets/images/${this.filePath}`);

        };

        // Define computed.
        const computed = {
            imageSource
        };

    //

    // EXPORT

        export default {
            name: 'Image',
            props,
            computed
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .white {
            filter: invert(97%) sepia(1%) saturate(277%) hue-rotate(339deg) brightness(117%) contrast(100%);
        }

        .green {
            filter: invert(39%) sepia(90%) saturate(312%) hue-rotate(139deg) brightness(100%) contrast(92%);
        }

        .blue {
            filter: invert(12%) sepia(98%) saturate(5147%) hue-rotate(187deg) brightness(90%) contrast(102%);
        }

        .red {
            filter: invert(14%) sepia(76%) saturate(7468%) hue-rotate(359deg) brightness(92%) contrast(119%);
        }

    //

</style>